(function($) {
  $(document).ready(function($) {
    $('.cd-timeline').prepend('<div class="cd-timeline-top"></div>');

    $.fn.almComplete = function(alm){
      $("a[href=#]", $('.cd-timeline-content')).contents().unwrap().parent().addClass('no-link');
    };

  });
})(jQuery);
